<template>
  <DgLayoutPortalAuth :logo="logo" :style="style" :language="language">
    <router-view />
  </DgLayoutPortalAuth>
</template>
<script>
import { LIST, DEFAULT_LANGUAGE } from "@/constants/language";
import store from "@/store";
import { DgLayoutPortalAuth } from "@engenharia/dashgoo-ui";

export default {
  components: {
    DgLayoutPortalAuth,
  },
  computed: {
    logo() {
      return store.state.portal.settings.image || null;
    },
    style() {
      const color = store.state.portal.settings.portalColor;
      const isMobile = window.innerWidth <= 600;
      const linearGradient = `linear-gradient(
          to right,
          ${color} 0%,
          ${color} 50%,
          white 50%,
          white 100%
        )`;
      const bgColor = isMobile ? color : linearGradient;
      const justifyContent = isMobile ? "flex-end" : "center";
      return {
        background: bgColor,
        display: "flex",
        justifyContent: justifyContent,
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
      };
    },
    language() {
      const paramLang =
        this.$route.query.lang ||
        this.$route.params.lang ||
        this.$route.query.lng ||
        this.$route.params.lng;

      const lang = paramLang || "";
      const item = LIST.find((_) => _.value === lang) || DEFAULT_LANGUAGE;
      return item.value;
    },
  },
  async mounted() {
    document.title = this.$t("MY_PANEL");
  },
};
</script>
